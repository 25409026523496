import React, { useState } from "react";
import classNames from "classnames";
import Button from "../ui/Button";
import Icon from "../ui/Icon";
import routesConfig from "../../routesConfig";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="fixed w-full xl:bg-transparent bg-gradient-to-b from-black xl:from-transparent to-transparent z-50 xl:h-auto h-20">
      <div className="mx-auto">
        <div className="flex items-center xl:justify-start justify-between relative px-4 sm:px-6 xl:px-8">
          <div>
            <a
              href="/"
              className="text-xl font-bold xl:text-gray-900 font-lora text-white"
            >
              Nicole & Rodrigo
            </a>
          </div>
          <div
            className={classNames(
              "flex xl:flex-row flex-col xl:relative absolute xl:bg-transparent bg-gray-100 transition-all xl:right-0 ease-in-out duration-200 xl:w-fit w-[100vw] top-0 xl:h-auto h-[100vh] items-center ms-0 xl:ms-2 justify-start px-4",
              {
                "right-0": isOpen,
                "-right-[100vw]": !isOpen,
              }
            )}
          >
            <div className="flex justify-between w-full container mx-auto border-b xl:hidden py-4 mb-4 item-center h-16">
              <a href="/" className="text-xl font-bold text-gray-900">
                Nicole & Rodrigo
              </a>
              <Button
                variant="empty"
                onClick={() => setIsOpen(!isOpen)}
                icon={<Icon name="close" size={20} />}
                className="items-center justify-center px-0 xl:hidden block -mr-6"
              ></Button>
            </div>
            {routesConfig
              .filter((route) => route.showInNavbar)
              .map((route) => (
                <a
                  key={route.path}
                  href={route.path}
                  className="text-gray-900 hover:text-gray-500 px-3 py-3 md:text-sm text-lg font-light xl:w-fit w-full"
                >
                  {route.name}
                </a>
              ))}
          </div>
          <div className="-mr-2 flex xl:hidden">
            <Button
              variant="empty"
              onClick={() => setIsOpen(!isOpen)}
              icon={<Icon name="menu" size={32} />}
              className="items-center justify-center p-2 xl:hidden flex text-white !px-0"
            >
              Menú
            </Button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
