import React from "react";

const Modal = ({ show, onClose, title, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="transform transition-all relative md:w-auto w-[92vw]"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          onClick={onClose}
          className="absolute -top-5 -right-5 bg-gray-300 w-10 h-10 flex items-center justify-center rounded-full pb-1"
        >
          <button className="text-gray-900 hover:text-gray-600 focus:outline-none focus:text-gray-600 font-light text-2xl">
            &times;
          </button>
        </div>
        <div className="bg-white rounded-lg shadow-xl transition-all md:max-h-[90vh] h-[90vh] md:w-[540px] w-full overflow-scroll">
          <div className="px-4 md:py-3 py-1 border-b border-gray-200 flex justify-between items-center relative mb-4">
            <h3 className="text-2xl leading-6 font-medium text-gray-900 py-2">
              {title}
            </h3>
          </div>
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
