// src/App.js

import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import routesConfig from "./routesConfig";
import Navbar from "./components/layout/Nav";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        {routesConfig.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.component />}
          />
        ))}
      </Routes>
    </Router>
  );
}

export default App;
