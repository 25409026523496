// src/routesConfig.js

import Home from "./pages/Home";
import Ubicacion from "./pages/Ubicacion";
import Vestimenta from "./pages/Vestimenta";
import Menu from "./pages/Menu";
import MesaRegalos from "./pages/MesaRegalos";
import Confirma from "./pages/Confirma";
import ConfirmacionAprobada from "./pages/ConfirmacionAprobada";

const routesConfig = [
  { name: "Home", path: "/", component: Home, showInNavbar: false },
  {
    name: "Ubicación",
    path: "/ubicacion",
    component: Ubicacion,
    showInNavbar: true,
  },
  {
    name: "Vestimenta",
    path: "/vestimenta",
    component: Vestimenta,
    showInNavbar: true,
  },
  { name: "Menú", path: "/menu", component: Menu, showInNavbar: true },
  {
    name: "Mesa de Regalos",
    path: "/mesa-de-regalos",
    component: MesaRegalos,
    showInNavbar: true,
  },
  {
    name: "R.S.V.P",
    path: "/confirma-asistencia",
    component: Confirma,
    showInNavbar: true,
  },
  {
    name: "Confirmación Aprobada",
    path: "/confirmacion-aprobada",
    component: ConfirmacionAprobada,
    showInNavbar: false,
  },
];

export default routesConfig;
