import React, { useState, useEffect } from "react";
import useBackgroundImageEffect from "../../hooks/useBackgroundImageEffect";

const Slideshow = ({ images, interval, elementId }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images.length, interval]);

  useBackgroundImageEffect(elementId);

  return (
    <div className="main-img lg:w-1/2 w-full lg:h-full h-96 lg:order-2 order-1 lg:fixed relative right-0 top-0 overflow-hidden flex items-center justify-center">
      <img
        id={elementId}
        src={images[currentIndex]}
        alt="Slideshow"
        className="absolute lg:w-full w-screen lg:h-full h-auto lg:object-cover object-center lg:scale-1 scale-150"
      />
    </div>
  );
};

export default Slideshow;
