import React from "react";
import { Link } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Button from "../components/ui/Button";
import Slideshow from "../components/ui/Slideshow";
import useBackgroundImageEffect from "../hooks/useBackgroundImageEffect";

const MesaRegalos = () => {
  useBackgroundImageEffect("background-image");

  const images = [
    "/images/mesa.jpg",
    "/images/mesa2.jpg",
    "/images/mesa3.jpg",
    "/images/mesa4.jpg",
  ];

  return (
    <Layout>
      <div className="flex flex-wrap h-[100vh] relative">
        <div className="lg:w-1/2 w-full flex lg:items-center lg:px-0 px-6 lg:py-0 py-8 lg:order-1 order-2 rounded-t-2xl lg:mt-0 -mt-16 z-10 bg-white lg:h-[100vh]">
          <div className="lg:w-8/12 w-full mx-auto lg:h-[70%] h-fit lg:flex lg:flex-col lg:justify-center lg:items-start">
            <p className="font-lora uppercase tracking-wider font-light">
              Regalos
            </p>
            <h1 className="font-lora text-6xl mb-8">Mesa de regalos</h1>
            <p className="mb-0 text-xl">¡Lo más especial es tu compañía! 🎁</p>
            <p className="mb-8">
              Pero si deseas darnos un regalo, te dejamos dos opciones:
            </p>
            <div className="">
              <div className="mb-6">
                <p className="font-bold mb-2">Cuenta para transferencia:</p>
                <div className="text-sm">
                  <p className="uppercase">NICOLE MARIE VILLAMIZAR SILVA</p>
                  <p className="uppercase">Santander</p>
                  <p>
                    <span className="uppercase">Clabe:</span>014180606318807137
                  </p>
                  <p>
                    <span className="uppercase">No. Cuenta:</span>60631880713
                  </p>
                  <p>
                    <span className="uppercase">Tarjeta:</span>5579070155430906
                  </p>
                  <p>
                    <span className="uppercase">Concepto:</span> Donación Boda +
                    Tu nombre
                  </p>
                </div>
              </div>
              <div className=" mb-6">
                <p className="font-bold mb-2">Mesa de regalos:</p>
                <Link
                  target="_blank"
                  to={"https://zepika.com/pages/nicole-y-rodrigo"}
                >
                  <Button
                    variant="outline"
                    className="w-full text-center justify-center"
                  >
                    Ir a tienda
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Slideshow
          images={images}
          interval={1000}
          elementId="background-image"
        />
      </div>
    </Layout>
  );
};

export default MesaRegalos;
