import React from "react";
import Layout from "../components/layout/Layout";
import useBackgroundImageEffect from "../hooks/useBackgroundImageEffect";
import Slideshow from "../components/ui/Slideshow";
import Button from "../components/ui/Button";

const ConfirmacionAprobada = () => {
  useBackgroundImageEffect("background-image");

  const images = [
    "/images/confirma.jpg",
    "/images/confirma2.jpg",
    "/images/confirma3.jpg",
  ];

  return (
    <Layout>
      <div className="flex flex-wrap h-[100vh] relative">
        <div className="lg:w-1/2 w-full flex lg:items-center lg:px-0 px-6 lg:py-0 py-8 lg:order-1 order-2 rounded-t-2xl lg:mt-0 -mt-16 z-10 bg-white lg:h-[100vh]">
          <div className="lg:w-8/12 w-full mx-auto lg:h-[70%] h-fit lg:flex lg:flex-col lg:justify-center lg:items-start">
            <p className="font-lora uppercase tracking-wider font-light">
              Gracias por acompañarnos
            </p>
            <h1 className="font-lora md:text-6xl text-4xl mb-8">
              ¡Tu confirmación fue exitosa!
            </h1>
            <a href="/mesa-de-regalos">
              <Button className="md:w-auto w-full" variant="outline">
                Mesa de Regalos
              </Button>
            </a>
          </div>
        </div>
        <Slideshow
          images={images}
          interval={1000}
          elementId="background-image"
        />
      </div>
    </Layout>
  );
};

export default ConfirmacionAprobada;
