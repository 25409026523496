import React from "react";
import Layout from "../components/layout/Layout";
import Button from "../components/ui/Button";
import Slideshow from "../components/ui/Slideshow";
import useBackgroundImageEffect from "../hooks/useBackgroundImageEffect";

const Ubicacion = () => {
  useBackgroundImageEffect("background-image");

  const images = ["/images/ubi.jpg", "/images/ubi2.jpg", "/images/ubi3.jpg"];

  return (
    <Layout>
      <div className="flex flex-wrap h-[100vh] relative">
        <div className="lg:w-1/2 w-full flex lg:items-center lg:px-0 px-6 lg:py-0 py-8 lg:order-1 order-2 rounded-t-2xl lg:mt-0 -mt-16 z-10 bg-white lg:h-[100vh]">
          <div className="lg:w-8/12 w-full mx-auto lg:h-[70%] h-fit lg:flex lg:flex-col lg:justify-center lg:items-start">
            <p className="font-lora uppercase tracking-wider font-light">
              Ubicación
            </p>
            <h1 className="font-lora text-6xl mb-8">Vinícola del bosque</h1>
            <p className="text-2xl">16 de Noviembre del 2024</p>
            <p className="mb-2">Recepción - 3:00pm</p>
            <p className="text-sm md:max-w-80 mb-8">
              Santa Rita 108, Col. Loma del Padre. Alcaldía. Cuajimalpa CDMX
              C.P. 05020
            </p>
            <p className="text-sm mb-8 text-slate-500 leading-3 md:w-96">
              *Contamos con estacionamiento, pero recomendamos llegar en taxi o
              Uber <span className="text-lg">🥂</span>
            </p>
            <a
              target="_blank"
              href="https://maps.app.goo.gl/8N2Re18eb629SaMs7?g_st=com.google.maps.preview.copy"
            >
              <Button variant="outline">Ver mapa</Button>
            </a>
          </div>
        </div>
        <Slideshow
          images={images}
          interval={1000}
          elementId="background-image"
        />
      </div>
    </Layout>
  );
};

export default Ubicacion;
