import React, { useState } from "react";

const MailchimpForm = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div id="mc_embed_shell" className="w-full flex justify-center">
      <div
        id="mc_embed_signup"
        className="w-full max-w-lg bg-white p-6 rounded-lg shadow-md"
      >
        <form
          action="https://gmail.us17.list-manage.com/subscribe/post?u=00b5c07ed90dd494fa402eeaa&amp;id=8fe5edd13d&amp;f_id=002cbae0f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate w-full space-y-4"
          target="_self"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <div className="indicates-required mb-4 text-sm">
              <span className="asterisk text-red-500">*</span> indica que es
              obligatorio
            </div>

            <div className="mc-field-group mb-4">
              <label htmlFor="mce-FNAME" className="block mb-2">
                Nombre(s) <span className="asterisk text-red-500">*</span>
              </label>
              <input
                type="text"
                name="FNAME"
                className="required text w-full border border-gray-300 rounded-md p-2"
                id="mce-FNAME"
                required
              />
            </div>

            <div className="mc-field-group mb-4">
              <label htmlFor="mce-PHONE" className="block mb-2">
                Teléfono <span className="asterisk text-red-500">*</span>
              </label>
              <input
                type="text"
                name="PHONE"
                className="REQ_CSS w-full border border-gray-300 rounded-md p-2"
                id="mce-PHONE"
                required
              />
            </div>

            <div className="mc-field-group mb-4">
              <label htmlFor="mce-EMAIL" className="block mb-2">
                Correo electrónico{" "}
                <span className="asterisk text-red-500">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                className="required email w-full border border-gray-300 rounded-md p-2"
                id="mce-EMAIL"
                required
              />
            </div>

            <div className="mc-field-group mb-4">
              <label htmlFor="mce-MMERGE6" className="block mb-2">
                Comida <span className="asterisk text-red-500">*</span>
              </label>
              <select
                name="MMERGE6"
                className="required w-full border border-gray-300 rounded-md p-2"
                id="mce-MMERGE6"
                required
              >
                <option value=""></option>
                <option value="Paella">Paella</option>
                <option value="Paella Campirana (sin mariscos)">
                  Paella Campirana (sin mariscos)
                </option>
                <option value="Menú Vegetariano">Menú Vegetariano</option>
              </select>
            </div>

            <div className="mc-field-group mb-4">
              <label htmlFor="mce-MMERGE7" className="block mb-2">
                Restricción alimenticia
              </label>
              <input
                type="text"
                name="MMERGE7"
                className="text w-full border border-gray-300 rounded-md p-2"
                id="mce-MMERGE7"
              />
            </div>

            <div className="mc-field-group input-group mb-4">
              <strong className="block mb-2">
                Invitación <span className="asterisk text-red-500">*</span>
              </strong>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <input
                    type="radio"
                    name="MMERGE11"
                    id="mce-MMERGE110"
                    value="Personal"
                    className="mr-2"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="mce-MMERGE110">Personal</label>
                </li>
                <li className="flex items-center">
                  <input
                    type="radio"
                    name="MMERGE11"
                    id="mce-MMERGE111"
                    value="Con Pareja"
                    className="mr-2"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="mce-MMERGE111">Con Pareja</label>
                </li>
                <li className="flex items-center">
                  <input
                    type="radio"
                    name="MMERGE11"
                    id="mce-MMERGE112"
                    value="Familiar"
                    className="mr-2"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="mce-MMERGE112">Familiar</label>
                </li>
              </ul>
            </div>

            {selectedOption === "Con Pareja" && (
              <>
                <div className="mc-field-group mb-4">
                  <label htmlFor="mce-MMERGE8" className="block mb-2">
                    Nombre de acompañante
                  </label>
                  <input
                    type="text"
                    name="MMERGE8"
                    className="text w-full border border-gray-300 rounded-md p-2"
                    id="mce-MMERGE8"
                  />
                </div>
                <div className="mc-field-group mb-4">
                  <label htmlFor="mce-MMERGE9" className="block mb-2">
                    Comida de acompañante
                  </label>
                  <select
                    name="MMERGE9"
                    className="w-full border border-gray-300 rounded-md p-2"
                    id="mce-MMERGE9"
                  >
                    <option value=""></option>
                    <option value="Paella">Paella</option>
                    <option value="Paella Campirana (sin mariscos)">
                      Paella Campirana (sin mariscos)
                    </option>
                    <option value="Menú Vegetariano">Menú Vegetariano</option>
                  </select>
                </div>
                <div className="mc-field-group mb-4">
                  <label htmlFor="mce-MMERGE10" className="block mb-2">
                    Restricción alimenticia de acompañante
                  </label>
                  <input
                    type="text"
                    name="MMERGE10"
                    className="text w-full border border-gray-300 rounded-md p-2"
                    id="mce-MMERGE10"
                  />
                </div>
              </>
            )}

            {selectedOption === "Familiar" && (
              <>
                <p className="mb-4">
                  Escoge la cantidad de menús para tus familiares:
                </p>
                <div className="md:flex md:gap-3 md:mr-4">
                  <div className="mc-field-group flex-1 mb-4">
                    <label htmlFor="mce-MMERGE12" className="block mb-2">
                      Paella
                    </label>
                    <input
                      type="number"
                      name="MMERGE12"
                      className="number w-full border border-gray-300 rounded-md p-2"
                      id="mce-MMERGE12"
                    />
                  </div>
                  <div className="mc-field-group flex-1 mb-4">
                    <label htmlFor="mce-MMERGE13" className="block mb-2">
                      Paella Campirana
                    </label>
                    <input
                      type="number"
                      name="MMERGE13"
                      className="number w-full border border-gray-300 rounded-md p-2"
                      id="mce-MMERGE13"
                    />
                  </div>
                  <div className="mc-field-group flex-1 mb-4">
                    <label htmlFor="mce-MMERGE14" className="block mb-2">
                      Menú Vegetariano
                    </label>
                    <input
                      type="number"
                      name="MMERGE14"
                      className="number w-full border border-gray-300 rounded-md p-2"
                      id="mce-MMERGE14"
                    />
                  </div>
                </div>
              </>
            )}

            <div id="mce-responses" className="clear foot">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>

            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              <input
                type="text"
                name="b_00b5c07ed90dd494fa402eeaa_8fe5edd13d"
                tabIndex="-1"
                defaultValue=""
              />
            </div>

            <div className="optionalParent mt-6">
              <div className="clear foot">
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button bg-teal-700 text-white rounded-md px-4 py-2 w-full hover:bg-teal-600"
                  value="Confirmar"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MailchimpForm;
