import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Button = ({
  children = null,
  onClick = () => {},
  className = "",
  icon = null,
  iconPosition = "left",
  variant = "primary",
  ...props
}) => {
  const variantClasses = {
    primary: "bg-teal-900 text-white hover:bg-teal-700",
    secondary: "bg-gray-500 text-white hover:bg-gray-600",
    link: "underline !px-0 hover:opacity-70",
    outline:
      "bg-transparent border border-teal-700 text-teal-700 hover:bg-teal-700 hover:text-white",
    empty: "bg-transparent hover:text-slate-500",
  };

  return (
    <button
      onClick={onClick}
      className={classnames(
        "px-5 py-3 flex items-center justify-center rounded-sm",
        variantClasses[variant],
        className
      )}
      {...props}
    >
      {icon && iconPosition === "left" && <span className="mr-2">{icon}</span>}
      {children}
      {icon && iconPosition === "right" && <span className="ml-2">{icon}</span>}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.element,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  variant: PropTypes.oneOf(["primary", "secondary", "outline", "empty"]),
};

export default Button;
