import { useEffect } from "react";

const useBackgroundImageEffect = (elementId, thresholdWidth = 996) => {
  useEffect(() => {
    const handleScroll = () => {
      // Check if screen width is greater than thresholdWidth (i.e., not mobile)
      if (window.innerWidth < thresholdWidth) {
        const scrollTop = window.scrollY;
        const backgroundImage = document.getElementById(elementId);

        if (backgroundImage) {
          // Adjust these values as needed for the desired effect
          const blurValue = Math.min(scrollTop / 100, 50); // Max blur of 50px
          const scaleValue = 1.5 - Math.min(scrollTop / 1000, 0.4); // Min scale of 1.1

          backgroundImage.style.filter = `blur(${blurValue}px)`;
          backgroundImage.style.transform = `scale(${scaleValue})`;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [elementId, thresholdWidth]);
};

export default useBackgroundImageEffect;
