import React from "react";
import Layout from "../components/layout/Layout";
import Button from "../components/ui/Button";
import Slideshow from "../components/ui/Slideshow";
import useBackgroundImageEffect from "../hooks/useBackgroundImageEffect";
import { Link } from "react-router-dom";

const Vestimenta = () => {
  useBackgroundImageEffect("background-image");

  const images = ["/images/ves.jpg", "/images/ves2.jpg", "/images/ves3.jpg"];

  return (
    <Layout>
      <div className="flex flex-wrap h-[100vh] relative">
        <div className="lg:w-1/2 w-full flex lg:items-center lg:px-0 px-6 lg:py-0 py-8 lg:order-1 order-2 rounded-t-2xl lg:mt-0 -mt-16 z-10 bg-white lg:h-[100vh]">
          <div className="lg:w-8/12 w-full mx-auto lg:h-[70%] h-fit lg:flex lg:flex-col lg:justify-center lg:items-start">
            <p className="font-lora uppercase tracking-wider font-light">
              Vestimenta
            </p>
            <h1 className="font-lora text-6xl mb-8">Formal de día</h1>
            <p className="text-xl mb-6">
              Por la época y la zona, esperamos frío en la noche ❄️
            </p>
            <p className="mb-2">
              <span className="font-bold">Mujeres:</span> Vestidos maxi o midi,
              zapatos cómodos para bailar (Es jardín y no habrá pantuflas).
            </p>
            <p className="mb-8">
              <span className="font-bold">Hombres:</span> Traje y corbata,
              colores azul marino o claros, zapatos cómodos para bailar.
            </p>
            <a target="_blank" href="https://pin.it/76D7FQroC">
              <Button variant="outline">Ver inspiración</Button>
            </a>
          </div>
        </div>
        <div className="lg:w-1/2 w-full lg:h-full h-96 lg:order-2 order-1 lg:fixed relative right-0 top-0 overflow-hidden">
          <Slideshow
            images={images}
            interval={1000}
            elementId="background-image"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Vestimenta;
