import React from "react";
import Layout from "../components/layout/Layout";
import Button from "../components/ui/Button";
import useBackgroundImageEffect from "../hooks/useBackgroundImageEffect";
import MailchimpForm from "../components/forms/MailchimpForm";
import Modal from "../components/layout/Modal";
import Slideshow from "../components/ui/Slideshow";
import useModal from "../hooks/useModal.jsx";

const Confirma = () => {
  useBackgroundImageEffect("background-image");

  const { showModal, openModal, closeModal } = useModal();

  const images = [
    "/images/confirma.jpg",
    "/images/confirma2.jpg",
    "/images/confirma3.jpg",
  ];

  return (
    <Layout>
      <div className="flex flex-wrap h-[100vh] relative">
        <div className="lg:w-1/2 w-full flex lg:items-center lg:px-0 px-6 lg:py-0 py-8 lg:order-1 order-2 rounded-t-2xl lg:mt-0 -mt-16 z-10 bg-white lg:h-[100vh]">
          <div className="lg:w-8/12 w-full mx-auto lg:h-[70%] h-fit lg:flex lg:flex-col lg:justify-center lg:items-start">
            <p className="font-lora uppercase tracking-wider font-light">
              R.S.V.P
            </p>
            <h1 className="font-lora text-6xl mb-8">Confirma tu asistencia</h1>
            <Button onClick={openModal} variant="primary">
              Confírma aquí
            </Button>
          </div>
        </div>
        <Slideshow
          images={images}
          interval={1000}
          elementId="background-image"
        />
      </div>
      <Modal
        show={showModal}
        onClose={closeModal}
        title="Confirma tu asitencia"
      >
        <MailchimpForm />
      </Modal>
    </Layout>
  );
};

export default Confirma;
